<script>
import { VclCode } from 'vue-content-loading';
import { mapState } from "vuex";

export default {
  components: {
    VclCode
  },
  props: {

  },
  computed: {
    ...mapState({
      username: 'username'
    })
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/banmanager/dashboard/general', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.data = data;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  },
  created() {
    this.getData()
    this.timer = setInterval(() => {this.getData();}, 1000 * 30);
  },
  destroyed() {
    if(this.timer) clearInterval(this.timer);
  },
  data() {
    return {
      ready: false,
      error: false,
      data: {},
      timer: null
    }
  }
};
</script>

<template>
  <div v-if="ready">
    <div class="row">
      <div class="col-lg-3 col-sm-12">
        <div class="card mini-stats-wid">
          <div class="card-body">
            <div class="media">
              <div class="media-body">
                <p class="fw-medium mb-2 text-uppercase h6">
                  Ban Lists
                </p>
                <h4 class="mb-0">
                  {{ $n(data.banmanager.banlists.count, {locale: 'de'}) }}
                </h4>
              </div>
              <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                <span class="avatar-title text-black">
                  <i class="fad fa-ban font-size-24"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- -->
      <div class="col-lg-3 col-sm-12">
        <div class="card mini-stats-wid">
          <div class="card-body">
            <div class="media">
              <div class="media-body">
                <p class="fw-medium mb-2 text-uppercase h6">
                  Verified
                </p>
                <h4 class="mb-0">
                  {{ $n(data.banmanager.banlists.verified, {locale: 'de'}) }}
                </h4>
              </div>
              <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-info">
                <span class="avatar-title bg-info text-black">
                  <i class="fad fa-badge-check font-size-24"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- -->
      <div class="col-lg-3 col-sm-12">
        <div class="card mini-stats-wid">
          <div class="card-body">
            <div class="media">
              <div class="media-body">
                <p class="fw-medium mb-2 text-uppercase h6">
                  Verification pending
                </p>
                <h4 class="mb-0">
                  {{ $n(data.banmanager.banlists.pending_verification, {locale: 'de'}) }}
                </h4>
              </div>
              <div class="avatar-sm align-self-center mini-stat-icon rounded-circle">
                <span class="avatar-title bg-warning text-black">
                  <i class="fad fa-badge font-size-24"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- -->
      <div class="col-lg-3 col-sm-12">
        <div class="card mini-stats-wid">
          <div class="card-body">
            <div class="media">
              <div class="media-body">
                <p class="fw-medium mb-2 text-uppercase h6">
                  Total ban entries
                </p>
                <h4 class="mb-0">
                  {{ $n(data.banmanager.bans.count, {locale: 'de'}) }}
                </h4>
              </div>
              <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                <span class="avatar-title bg-danger text-black">
                  <i class="fad fa-axe-battle font-size-24"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="error">
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
            </h5>
          </div>
          <div class="card-body">
            <h5 class="card-title mt-0">
              {{ $t('dashboard.error.info') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <vcl-code :height="90"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
